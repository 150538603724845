import * as styles from "../Login/sign-in-page.module.css";

import { Button, Input, Text, VStack, useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import { gql, useMutation } from "@apollo/client";

import { navigate } from "gatsby";
import { useLocation } from "@reach/router";
import validator from "validator";

const RESET_PASSWORD = gql`
    mutation ResetPassword($key: String = ""
    $password: String = ""
    $login: String = "") {
        resetUserPassword(input: { key: $key, password: $password, login: $login }) {
            clientMutationId
        }
    }
`;

const SetPasswordForm: React.FC = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const resetPasswordKey = searchParams.get("key");
    const login = searchParams.get("login");

    const [newPassword, setNewPassword] = useState("");
    const [newPasswordError, setNewPasswordError] = useState("");

    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const doPasswordsMatch = newPassword == confirmPassword;

    const [showPassword, setShowPassword] = useState(false);

    const [resetPassword, { loading }] = useMutation(RESET_PASSWORD);

    const handlePasswordReset = async (e: React.FormEvent) => {
        e.preventDefault();
        resetPassword({
            variables: {
                key: resetPasswordKey,
                password: newPassword,
                login,
            },
            onCompleted: () => {
                navigate("/log-in");
            },
        });
    };

    const passwordValidation = (e: React.FormEvent): void => {
        e.preventDefault();
        let errored = false;

        // password check
        if (
            !validator.isStrongPassword(newPassword, {
                minLength: 8,
                minLowercase: 1,
                minUppercase: 1,
                minNumbers: 1,
                minSymbols: 1,
                returnScore: false,
                pointsPerUnique: 1,
                pointsPerRepeat: 0.5,
                pointsForContainingLower: 10,
                pointsForContainingUpper: 10,
                pointsForContainingNumber: 10,
                pointsForContainingSymbol: 10,
            })
        ) {
            setNewPasswordError(
                "Passwords should contain a minimum of 8 characters, using a mix of uppercase and lowercase letters, numbers, andspecial characters.",
            );
            errored = true;
        } else {
            setNewPasswordError("");
        }

        // confirm password check
        if (!doPasswordsMatch) {
            setConfirmPasswordError("Passwords don't match");
            errored = true;
        } else {
            setConfirmPasswordError("");
        }

        if (!errored) {
            handlePasswordReset(e);
        }
    };

    return (
        <div className={styles.signInPage}>
            <div className={styles.registerSection}>
                <div className={styles.registerContent}>
                    <div className={styles.contactForm}>
                        <form method="post" onSubmit={passwordValidation}>
                            <div className={styles.contactFormInputs}>
                                <VStack alignItems="flex-start" pb="16px">
                                    <Text fontSize="30px" className={styles.display}>Reset Your CDN Health Password Easily</Text>
                                    <Text> Please choose your new password and repeat it.
                                    </Text>
                                </VStack>
                                <div className={styles.inputRow}>
                                    <VStack className={styles.inputFieldWIthLabel}>
                                        <VStack w="100%" >
                                            <div className={styles.inputLabel}>
                                                <div className={styles.email}>Enter new password</div>
                                            </div>
                                            <Input
                                                id="new-password"
                                                type={showPassword ? "text" : "password"}
                                                name="new-password"
                                                required
                                                variant="outline"
                                                textColor="#1a202c"
                                                borderColor="#e2e8f0"
                                                focusBorderColor="#3182ce"
                                                placeholder="Enter new password"
                                                value={newPassword}
                                                onChange={(e) => setNewPassword(e.currentTarget.value)}
                                            />
                                            {newPasswordError && <Text color="red.500" marginTop="0px !important">{newPasswordError}</Text>}
                                        </VStack>


                                        <VStack w="100%" alignItems="flex-start">
                                            <div className={styles.inputLabel}>
                                                <div className={styles.email}>Confirm new password</div>
                                            </div>
                                            <Input
                                                id="confirm-password"
                                                type={showPassword ? "text" : "password"}
                                                name="confirm-password"
                                                required
                                                variant="outline"
                                                textColor="#1a202c"
                                                borderColor="#e2e8f0"
                                                focusBorderColor="#3182ce"
                                                placeholder="Confirm new password"
                                                value={confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.currentTarget.value)}

                                            />

                                            {confirmPasswordError && <Text color="red.500" marginTop="0px !important">{confirmPasswordError}</Text>}
                                        </VStack>
                                        <Text as="a" onClick={() => setShowPassword(!showPassword)} cursor="pointer" color="blue.500" w="100%" textAlign="right" marginTop="0px !important">
                                            {showPassword ? "Hide Passwords" : "Show Passwords"}
                                        </Text>
                                    </VStack>
                                </div>
                                <Button
                                    variant="solid"
                                    colorScheme="cdicyan"
                                    type="submit"
                                >
                                    {loading ? "Loading..." : "Set New Password"}

                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
                <img
                    className={styles.contactDetailsIcon}
                    alt=""
                    src="https://shop.cdihealth.net/wp-content/uploads/sites/6/2024/12/contactDetails.png"
                />
            </div>
        </div>
    );
};

export default SetPasswordForm;
